import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptSingleTotal from './views/RptSingleTotal';

import { errorMessage, catName } from '../functions';

const mapStateToProps = (state) => {
        return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
                periodType: state.periodType,
                periodDate: state.periodDate
        }
}

const mapDispatchToProps = { errorMessage }

function RptOthersCategorySingleTotal(props){
	const [rpt_cat, setRptCat] = useState([]);

        const refresh = () => {
                console.log('RptOthersCategorySingleTotal.js refresh invoked');

                var F1031 = props.periodType;
                var F254 = props.periodDate;

                console.log('RptOthersCategorySingleTotal.js requesting: ' + props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_cat&agent='+props.agent+'&F1031='+F1031+'&F254='+F254+'&F1034=3');
                fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_cat&agent='+props.agent+'&F1031='+F1031+'&F254='+F254+'&F1034=3')
		.then(response => response.json())
		.then(result => {
                        console.log('RptOthersCategorySingleTotal.js rpt_cat result returned');
                        console.log('RptOthersCategorySingleTotal.js rpt_cat result: '+JSON.stringify(result));
			if(result.data){
	                        setRptCat(result.data);
			}
			else{
				props.errorMessage("Error retrieving rpt_cat data: "+result.message);
			}
                });
        }

	let rptData = rpt_cat.map((record,index) => {
		return {
			code: record.F17,
			description: catName(rpt_cat, record.F17),
			qty: record.F64,
			dollars: record.F65
		};
	});

	return (
		<ReportTab id='CategorySingleTotal'>
			<ReportTab.Header parentId='CategorySingleTotal' title='Category Single Total'>
				<BtnRefresh parentId='CategorySingleTotal' action={refresh} />
				<BtnExpand parentId='CategorySingleTotal' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='CategorySingleTotal'>
				<RptSingleTotal title='Category Net Sales' description='Category Code' data={rptData} />
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptOthersCategorySingleTotal);

