import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptNoData from './views/RptNoData';

import { errorMessage, tlzName } from '../functions';

const mapStateToProps = (state) => {
	return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
		periodType: state.periodType,
		periodDate: state.periodDate,
		clk_tab: state.clk_tab,
		tlz_tab: state.tlz_tab
	}
}

const mapDispatchToProps = { errorMessage }

function RptCashierShortOver(props){
	const [rpt_clk, setRptClk] = useState([]);

        const refresh = () => {
                console.log('RptCashierShortOver.js refresh invoked');

                var F1031 = props.periodType;
                var F254 = props.periodDate;

                console.log('RptCashierShortOver.js requesting: ' + props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_clk_'+F1031+'&agent='+props.agent+'&F254='+F254);
                fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_clk_'+F1031+'&agent='+props.agent+'&F254='+F254)
		.then(response => response.json())
		.then(result => {
                        console.log('RptCashierShortOver.js rpt_clk result returned');
                        console.log('RptCashierShortOver.js rpt_clk result: '+JSON.stringify(result));
			if(result.data){
	                        setRptClk(result.data);
			}
			else{
				props.errorMessage("Error retrieving rpt_clk data: "+result.message);
			}
                });
        };

        const inRptClk = (operator) => {
                if(rpt_clk && rpt_clk.length > 0){
			let rpt_shortovers =rpt_clk.filter((record) => record.F1034 > 1400 && record.F1034 < 1499); 
                        if(rpt_shortovers.filter((record) => record.F1185 === operator.F1185).length > 0){
                                return true;
                        }
                }
                return false;
        };

		var operator_so;
		if(props.clk_tab && props.clk_tab.length > 0){
			operator_so = props.clk_tab.filter(inRptClk);
			console.log('RptCashierShortOver.js operator_so: '+JSON.stringify(operator_so));
		}

		var shortover;
		if(rpt_clk && rpt_clk.length > 0){
			let rpt_shortovers = rpt_clk.filter((record) => record.F1034 > 1400 && record.F1034 < 1499);
			if(rpt_shortovers && rpt_shortovers.length > 0 && operator_so && operator_so.length > 0){
				shortover = operator_so.map(function(clk,index){
					let so_details = rpt_shortovers.filter((record) => record.F1185 === clk.F1185).map((record, index) => (
						<div key={'so_details_'+clk.F1126+'_'+index} className='row'>
							<div className='col-6 col-sm-6'><div className='ml-3'>{tlzName(props.tlz_tab, record.F1034 - 1300)}</div></div>
							<div className='col-3 col-sm-3'>{record.F64.toFixed(0) !== 0 ? record.F64.toFixed(0) : null}</div>
							<div className='col-3 col-sm-3'>{record.F65.toFixed(2) !== 0.00 ? record.F65.toFixed(2) : null}</div>
						</div>
					));

					return (
						<div key={'clk_'+clk.F1126}>
							<div className='row'>
								<div className='col-12 col-sm-12'><b>{clk.F1126} {clk.F1127}</b></div>
							</div>
							{so_details}
						</div>
					);
				});
			}
		}

	const CashierShortOver = () => {
		if(rpt_clk.length > 0){
			return (
				<div id='ReportData' className='container'>
					<div className='row'>
						<div className='col-6 col-sm-6'><div className='ml-3'><b>Description</b></div></div>
						<div className='col-3 col-sm-3'><b>Qty</b></div>
						<div className='col-3 col-sm-3'><b>Amt</b></div>
					</div>
					<hr className='m-1 p-0' />
					{shortover}
				</div>
			);
		}
		else{
			return(
				<RptNoData />
			);
		}
	}

	return (
		<ReportTab id='CashierShortOver'>
			<ReportTab.Header parentId='CashierShortOver' title='Cashier Short / Over'>
				<BtnRefresh parentId='CashierShortOver' action={refresh} />
				<BtnExpand parentId='CashierShortOver'action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='CashierShortOver'>
				{CashierShortOver()}
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptCashierShortOver);

