import { tlzName, tlzDetail, tlzSectionNum, onlyUnique, rptTotal, rptTotalQty } from '../../functions';

function RptSummaryInformation(props) {
    var summary_tlz = props.data.filter((record,index) => (((tlzDetail(props.data, record.F1034, "F1179") < 3000 || tlzDetail(props.data, record.F1034, "F1179") > 4900) && tlzDetail(props.data, record.F1034, "F1179") !== 1700)) && !(record.F1034 >= 4000 && record.F1034 <= 4004));

    var summary_details = summary_tlz.map((record) => tlzDetail(props.data, record.F1034, "F1129")).filter(onlyUnique).sort(function(a,b){
        return tlzSectionNum(props.data, a) - tlzSectionNum(props.data, b);
    }).map(function(section,index){
        var section_detail_tmp = summary_tlz.filter((record) => tlzDetail(props.data, record.F1034, "F1129") === section).map(record => record.F1034).filter(onlyUnique).sort(function(a,b){
            return tlzDetail(props.data, a, "F1147") - tlzDetail(props.data, b, "F1147");
        });

        console.log('RptStoreTotalSummary.js section_detail_tmp: '+JSON.stringify(section_detail_tmp));
        var section_details = section_detail_tmp.map((record,rpt_index) =>
                                    <div key={'tss_details_'+index+'_'+rpt_index} className='row'>
                                            <div className='col-6 col-sm-6'><div className='ml-3'>{tlzName(props.data, record)}</div></div>
                                            <div className='col-3 col-sm-3'>{rptTotalQty(props.data, record).toFixed(0) !== 0 ? rptTotalQty(props.data, record).toFixed(0) : null}</div>
                                        <div className='col-3 col-sm-3'>{rptTotal(props.data, record).toFixed(2) !== 0.00 ? rptTotal(props.data, record).toFixed(2) : null}</div>
            </div>
        );

                        return (
            <div key={'tss_'+index}>
                                        <div className='row'>
                                                <div className='col-12 col-sm-12'><b>{section}</b></div>
                    </div>

                {section_details}
            </div>
                        );
                });


    return (
        <>
            <div className='mt-2 row bg-secondary text-white'>
                <div className='col-6 col-sm-6'><b>Description</b></div>
                <div className='col-3 col-sm-3'><b>Qty</b></div>
                <div className='col-3 col-sm-3'><b>Amt</b></div>
            </div>

            {summary_details}
        </>
    );
}

export default RptSummaryInformation;