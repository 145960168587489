import React from 'react';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import RptNoData from './RptNoData';

let dollarUSLocale = Intl.NumberFormat('en-US');

function RptMultiTotal(props){
    // debugger;
    if(!props.data){
        return (
            <RptNoData />
        );
    }

	if(props.data.length > 0){
		console.log('RptMultiTotal.js data: '+JSON.stringify(props.data));

		const multitotal = () => {
			return props.data.map((record,index) => {
				return (
                    <div key={'mt_'+index}>
                        <div className='row'>
                                <div className='col-12'><b>{record.description}</b></div>
                        </div>
                        {multitotal_details(record.data)}
                        {record.total ? <div className='row'>
                                <div className='col-6' />
                                <div className='col-6 text-end'><b>Total: {dollarUSLocale.format(record.total.toFixed(2))}</b></div>
                            </div> : null }
                    </div>
                );
			});
		}

		const multitotal_details = (data) => {
			console.log('RptMultiTotal.js multitotal_details data = '+JSON.stringify(data));
			return data.map((record, index) => (
                               	<div key={'mt_details_'+index} className='row'>
                                        <div className='col-6'><div className='ms-3'>{record.description}</div></div>
                                        <div className='col-3 text-end'>{record.qty.toFixed(0) !== 0 ? record.qty.toFixed(0) : null}</div>
                                	<div className='col-3 text-end'>{dollarUSLocale.format(record.dollars.toFixed(2)) !== 0.00 ? record.dollars.toFixed(2) : null}</div>
				</div>
			));
		}

    		return (
			<div id='ReportData' className='container'>
				<div className='row'>
					<div className='col-6'><div className='ms-3'><b>Description</b></div></div>
					<div className='col-3 text-end'><b>Qty</b></div>
					<div className='col-3 text-end'><b>Amt</b></div>
				</div>
				<hr className='m-1 p-0' />
				{multitotal()}
			</div>
		);
	}
	else{
		return (
			<RptNoData />
		);
	}
}

export default RptMultiTotal;
