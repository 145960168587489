import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GenerateForm from './GenerateForm';
import { loginUser, errorMessage } from './functions';

const mapStateToProps = (state) => {
	return {
		apiServer: state.apiServer
	}
}

const mapDispatchToProps = { loginUser, errorMessage }

function LoginForm(props){
	let navigate = useNavigate();
	let location = useLocation();

	const login = (formState) => {
		console.log('LoginForm.js login() invoked');
		let formData = new FormData();
		formData.append('action','login');
		formData.append('email',formState.input_username);
		formData.append('password',formState.input_password);

		fetch(props.apiServer, {
			method: 'POST',
			body: formData
		})
		.then(response => response.json())
		.then(result => {
			if(result.success === true){
                console.log('LoginForm.js location.state: '+JSON.stringify(location.state));
                props.loginUser(formState.input_username, result.uid, result.session, result.isAdmin);
                navigate("/", {state: {...location.state, ...{
                    username: formState.input_username,
                    uid: result.uid,
                    session: result.session,
                    isAdmin: result.isAdmin
                }}});
			}
			else{
                props.errorMessage(result.message);
			}
		})
		.catch(error => {
			console.error('Error:',error);
		});
	}

	let form = {
		fields: [
			{
				name: 'input_username',
				label: 'Email',
				type: 'email',
				required: true,
				autocomplete: 'username'
			},
			{
				name: 'input_password',
				label: 'Password',
				type: 'password',
				required: true,
				autocomplete: 'current-password'
			}
		],
		primaryBtn: {
			action: login,
			label: 'Login',
			type: 'wide',
			class: 'btn-primary'
		}
	};

    return (
        <div className='card bg-light rounded border border-dark shadow mt-2 mb-0 ml-auto mr-auto p-auto'>
            <div className='card-header theme-primary p-2'><h5 className='m-0'>Login</h5></div>
            <div className='mt-2 ml-2 mr-2 mb-0 p-2'>
                <GenerateForm form={form} />
            </div>
        </div>
    );
}


export default connect(
        mapStateToProps,
        mapDispatchToProps
)(LoginForm);

