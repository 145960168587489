import React from 'react';
import { connect } from 'react-redux';

import {
        Chart as ChartJS,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import RptNoData from './RptNoData';

ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend
);

const mapStateToProps = (state) => {
	return {		
        lastWeekColor: state.lastWeekColor,
        thisWeekColor: state.thisWeekColor
	}
}

function Rpt2SingleTotal(props){
	if(!props.data){
		return (
			<RptNoData />
		);
	}

	console.log('Rpt2SingleTotal.js props: '+JSON.stringify(props));

	if(props.data.length > 0){
		console.log('RptSingleTotal.js data: '+JSON.stringify(props.data));

		let bar_labels = props.data.map((record) => record.description);

		let bar_data = {
		        labels: bar_labels,
		        datasets: [
		                {
							label: props.label1,
							data: props.data.map((record) => record.total1.toFixed(2)),
							borderColor: props.thisWeekColor,
							backgroundColor: `${props.thisWeekColor}80`
		                },
		                {
							label: props.label2,
							data: props.data.map((record) => record.total2.toFixed(2)),
							borderColor: props.thisWeekColor,
							backgroundColor: `${props.thisWeekColor}80`
		                }						
		        ],
		};

		let  bar_options = {
			indexAxis: 'y',
			elements: {
					bar: {
							borderWidth: 2,
					},
			},
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: true,
					position: 'bottom',
					labels: {
							usePointStyle: true,
							pointStyle: 'rect',
					},
				},
				title: {
					display: true,
					text: [props.title],
					font: {
							size: 18,
					}
				},
			}
		};

		let bar_height = props.data.length * 30 + 150;

		return (
			<div id='ReportData' className='container'>
				<div style={{height: bar_height+'px'}}><Bar options={bar_options} data={bar_data} /></div>
				<div className='d-flex justify-content-center fw-bold' style={{color: 'rgb(25, 135, 84)'}}>{props.label1} Total: ${props.total1.toFixed(2)}</div>
				<div className='d-flex justify-content-center fw-bold mb-3' style={{color: 'rgb(87, 138, 200)'}}>{props.label2} Total: ${props.total2.toFixed(2)}</div>	
							
				<div className='row'>
					<div className='col-sm-3 d-none d-sm-block m-0 p-1'>&nbsp;</div>
					<div className='col-6 col-sm-5 m-0 p-1'><b>{ props.description ? props.description : 'Description' }</b></div>
					<div className='col-3 col-sm-2 m-0 p-1 text-end'><b>{props.label1}</b></div>
					<div className='col-3 col-sm-2 m-0 p-1 text-end'><b>{props.label2}</b></div>
				</div>
				<hr className='m-1 p-0' />
{
	props.data.map((record,index) => (
		<div key={index} className='row'>
			<div className='col-sm-3 d-none d-sm-block m-0 p-1'>{record.code}</div>
			<div className='col-6 col-sm-5 m-0 p-1'>{record.description}</div>
			<div className='col-3 col-sm-2 m-0 p-1 text-end'>{record.total1.toFixed(2) !== 0.00 ? record.total1.toFixed(2) : null}</div>
			<div className='col-3 col-sm-2 m-0 p-1 text-end'>{record.total2.toFixed(2) !== 0.00 ? record.total2.toFixed(2) : null}</div>
		</div>
	))
}
				<div className='row'>
					<div className='col-6 col-sm-8 fw-bold m-0 p-1 text-end'>Total</div>
					<div className='col-3 col-sm-2 fw-bold m-0 p-1 text-end'>${props.total1.toFixed(2)}</div>
					<div className='col-3 col-sm-2 fw-bold m-0 p-1 text-end'>${props.total2.toFixed(2)}</div>
				</div>


			</div>
		);
	}
	else{
		return (
			<RptNoData />
		);
	}
}

export default connect(
    mapStateToProps
)(Rpt2SingleTotal);

