import { Collapse } from 'bootstrap';

function BtnRefresh (props) {
	const refresh = () => {
		console.log('BtnRefresh.js refresh invoked');
		let refreshIcon = document.getElementById(props.parentId+'Refresh');
		refreshIcon.classList.add("refresh");
			window.setTimeout( function () { refreshIcon.classList.remove("refresh"); }, 500 );

		props.action();
		let collapsible = document.getElementById('collapse_'+props.parentId);
		if(!collapsible.classList.contains("show")){
			console.log('BtnRefresh.js bootstrap expand');
			new Collapse(document.getElementById('collapse_'+props.parentId));
		}
	}

    return (
        <button className='m-1 btn btn-sm btn-success' onClick={refresh}><i id={props.parentId+'Refresh'} className='fas fa-sync-alt' /></button>
    )
}

export default BtnRefresh;