import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GenerateForm from './GenerateForm';
import ReportTab from './ReportTab';
import BtnClose from './buttons/BtnClose';

import { errorMessage } from './functions';

const mapStateToProps = (state) => {
	return {
		version: state.version,
		apiServer: state.apiSever,
		username: state.username,
		uid: state.uid,
		session: state.session,
		isAdmin: state.isAdmin
	}
}

const mapDispatchToProps = { errorMessage }

function ChangePassword(props){
	let navigate = useNavigate();
	let location = useLocation();

	const changePassword = (frmState) => {
		if(frmState.newPassword1 === frmState.newPassword2){
			console.log('ChangePassword.js changePassword invoked');
			fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=change_password&cur_password='+frmState.input_curPassword+'&new_password='+frmState.input_newPassword1)
			.then(response => response.json())
			.then(result => {
				console.log('ChangePassword.js changePassword result returned');
				console.log('ChangePassword.js changePassword result: '+JSON.stringify(result));
				if(result.success === true){
						props.showView('ReportList');
				}
				else{
						props.errorMessage(result.message);
				}
			});
		}
		else{
			props.errorMessage("New password does not match confirm new password");
		}
	};

	let form = {
		fields: [
			{
				name: 'input_username',
				type: 'hidden',
				autocomplete: 'username'
			},
			{
				name: 'input_curPassword',
				label: 'Current Password',
				type: 'password',
				required: true,
				autocomplete: 'current-password'
			},
			{
				name: 'input_newPassword1',
				label: 'New Password',
				type: 'password',
				required: true,
				autocomplete: 'new-password'
			},
			{
				name: 'input_newPassword2',
				label: 'New Password (Confirm)',
				type: 'password',
				required: true,
				autocomplete: 'new-password'
			}
		],
		primaryBtn: {
			action: changePassword,
			label: 'Change Password',
			type: 'wide',
			class: 'btn-primary'
		}
	};

	return (
		<ReportTab>
			<ReportTab.Header title='Change Password'>
				<BtnClose action={() => navigate('/', {state: {...location.state}})} />
			</ReportTab.Header>
			<ReportTab.AlwaysShow>
				<div className='m-0 p-2'><GenerateForm form={form} /></div>
			</ReportTab.AlwaysShow>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(ChangePassword);

