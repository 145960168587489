import React from 'react';
import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer'
import {
        Chart as ChartJS,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend,
} from 'chart.js';

ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        BarElement,
        Title,
        Tooltip,
        Legend
);

const styles = StyleSheet.create({
	page: {
		padding: 30
	},
	row: {
		flexDirection: 'row'
	},
	hr: {
		borderBottomColor: 'black',
    	borderBottomWidth: 1,
	},
	bgGrey: {
		backgroundColor: '#e6e6e6'
	},
	fs10: {
		fontSize: 10
	},
	fs12: {
		fontSize: 12
	},
	fwBold: {
		fontWeight: 'bold'
	},
	code: {
		width: 50
	},
	description: {
		width: 300
	},
	qty: {
		width: 100
	},
	amt: {
		width: 100
	}
});

function RptSingleTotalPDF(props){
	console.log('RptSingleTotalPDF.js data: '+JSON.stringify(props.data));

	return (
		<Document>
			{props.chart ? <Page size='A4' style={styles.page}><View><Image src={props.chart} width='500' height='700' /></View></Page> : null}

			<Page size='A4' style={styles.page}>

			<View style={[styles.row, styles.hr]}>
				<Text style={[styles.code, styles.fs12, styles.fwBold]}>&nbsp;</Text>
				<Text style={[styles.description, styles.fs12, styles.fwBold]}>{ props.description ? props.description : 'Description' }</Text>
				<Text style={[styles.qty, styles.fs12, styles.fwBold]}>Qty</Text>
				<Text style={[styles.amt, styles.fs12, styles.fwBold]}>Amt</Text>
			</View>
{
props.data.map((record,index) => {
	if(!(index%2)){
		return (
			<View key={index} style={styles.row}>
				<Text style={[styles.code, styles.fs10]}>{record.code}</Text>
				<Text style={[styles.description, styles.fs10]}>{record.description}</Text>
				<Text style={[styles.qty, styles.fs10]}>{record.qty.toFixed(0)}</Text>
				<Text style={[styles.amt, styles.fs10]}>{record.dollars.toFixed(2)}</Text>
			</View>
		);
	}
	else {
		return (
			<View key={index} style={[styles.row, styles.bgGrey]}>
				<Text style={[styles.code, styles.fs10]}>{record.code}</Text>
				<Text style={[styles.description, styles.fs10]}>{record.description}</Text>
				<Text style={[styles.qty, styles.fs10]}>{record.qty.toFixed(0)}</Text>
				<Text style={[styles.amt, styles.fs10]}>{record.dollars.toFixed(2)}</Text>
			</View>
		);	
	}
})
}

			</Page>
		</Document>
	);
}

export default RptSingleTotalPDF;
