import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { deptName, tlzName, tlzDetail, errorMessage, onlyUnique, rptTotal } from '../functions';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptNoData from './views/RptNoData';

const mapStateToProps = (state) => {
	return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
		periodType: state.periodType,
		periodDate: state.periodDate
	}
}

const mapDispatchToProps = { errorMessage }

function RptStoreBalanceSheet(props){
	const [rpt_fin, setRptFin] = useState([]);
	const [rpt_dpt, setRptDpt] = useState([]);

	const totalSales = () => {
		if(rpt_dpt.filter(rpt => rpt.F1034 === 3 || rpt.F1034 === 6).length > 0){
			return rpt_dpt.filter(rpt => rpt.F1034 === 3 || rpt.F1034 === 6).reduce((a, b) => { 
					return a + b.F65
				}, 0);
		}
		return 0;
	};

	const totalTaxes = () => {
		console.log('RptStoreBalanceSheet.js totalTaxes invoked');
		if(rpt_fin.filter(rpt => tlzDetail(rpt_fin, rpt.F1034, 'F1179') === 1400 && tlzDetail(rpt_fin, rpt.F1034, 'F1253') === 'C').length > 0){
			console.log('Taxes found');
			return rpt_fin.filter(rpt => tlzDetail(rpt_fin, rpt.F1034, 'F1179') === 1400 && tlzDetail(rpt_fin, rpt.F1034, 'F1253') === 'C').reduce((a, b) => { 
					return a + b.F65
				}, 0);
		}
		return 0;
	};

	const totalPlus = () => {
		console.log('RptStoreBalanceSheet.js totalPlus invoked');
		if(rpt_dpt.filter(rpt => tlzDetail(rpt_dpt, rpt.F1034, 'F1179') === 1500 && tlzDetail(rpt_dpt, rpt.F1034, 'F1253') === 'C').length > 0){
			console.log('Plus found');
			return rpt_dpt.filter(rpt => tlzDetail(rpt_dpt, rpt.F1034, 'F1179') === 1500 && tlzDetail(rpt_dpt, rpt.F1034, 'F1253') === 'C').reduce((a, b) => { 
					return a + b.F65
				}, 0);
		}
		return 0;
	};

	const totalRevenue = () => {
		if(rpt_fin.filter(rpt => (rpt.F1034 >= 900 && rpt.F1034 <= 999)).length > 0){
			return rpt_fin.filter(rpt => (rpt.F1034 >= 900 && rpt.F1034 <= 999)).reduce((a, b) => {
                                        return a + b.F65
                                }, 0);
		}
		return 0;
	};

	const totalLoans = () => {
		if(rpt_fin.filter(rpt => (rpt.F1034 >= 1000 && rpt.F1034 <= 1099)).length > 0){
			return rpt_fin.filter(rpt => (rpt.F1034 >= 1000 && rpt.F1034 <= 1099)).reduce((a, b) => {
                                        return a + b.F65
                                }, 0);
		}
		return 0;
	};

	const totalPickups = () => {
		if(rpt_fin.filter(rpt => (rpt.F1034 >= 1100 && rpt.F1034 <= 1199)).length > 0){
			return rpt_fin.filter(rpt => (rpt.F1034 >= 1100 && rpt.F1034 <= 1199)).reduce((a, b) => {
                                        return a + b.F65
                                }, 0);
		}
		return 0;
	};

	const totalInDrawer = () => {
		if(rpt_fin.filter(rpt => (rpt.F1034 >= 1300 && rpt.F1034 <= 1399)).length > 0){
			return rpt_fin.filter(rpt => (rpt.F1034 >= 1300 && rpt.F1034 <= 1399)).reduce((a, b) => {
                                        return a + b.F65
                                }, 0);
		}
		return 0;
	};

	const totalShortOver = () => {
		if(rpt_fin.filter(rpt => (rpt.F1034 >= 1400 && rpt.F1034 <= 1499)).length > 0){
			return rpt_fin.filter(rpt => (rpt.F1034 >= 1400 && rpt.F1034 <= 1499)).reduce((a, b) => {
                                        return a + b.F65
                                }, 0);
		}
		return 0;
	};

	const totalCredits = () => {
		return totalSales() + totalTaxes() + totalPlus() + totalShortOver();
	};

	const totalDebits = () => {
		return totalPickups() + totalInDrawer();
	};

        const rpt_dptTotal = (dept) => {
                if(rpt_dpt.filter(rpt => (rpt.F03 === dept)).filter(rpt => (rpt.F1034 === 3 || rpt.F1034 === 6)).length > 0){
                        return rpt_dpt.filter(rpt => (rpt.F03 === dept)).filter(rpt => (rpt.F1034 === 3 || rpt.F1034 === 6)).reduce((a, b) => {
                                        return a + b.F65
                                }, 0);
                }
                return 0;
        };

	const refresh = () => {
		console.log('RptStoreBalanceSheet.js refresh invoked');

		var F1031 = props.periodType;
		var F254 = props.periodDate;

		console.log('RptStoreBalanceSheet.js requesting: '+props.apiServer+'?uid='+props.uid+'&session='+props.session+'&action=rpt_dpt&agent='+props.agent+'&F1031='+F1031+'&F254='+F254);
		fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_dpt&agent='+props.agent+'&F1031='+F1031+'&F254='+F254)
		.then(response => response.json())
		.then(result => {
			console.log('RptStoreBalanceSheet.js rpt_dpt result returned');
			console.log('RptStoreBalanceSheet.js rpt_dpt result: '+JSON.stringify(result));
			if(result.data){
				setRptDpt(result.data);
			}
			else{
				props.errorMessage("Error retrieving rpt_dpt data: "+result.message);
			}
		});

		console.log('RptStoreBalanceSheet.js requesting: '+props.apiServer+'?uid='+props.uid+'&session='+props.session+'&action=rpt_fin&agent='+props.agent+'&F1031='+F1031+'&F254='+F254);
		fetch(props.apiServer+'?uid='+props.uid+'&session='+props.session+'&action=rpt_fin&agent='+props.agent+'&F1031='+F1031+'&F254='+F254)
		.then(response => response.json())
		.then(result => {
			console.log('RptStoreBalanceSheet.js rpt_fin result returned');
			console.log('RptStoreBalanceSheet.js rpt_fin result: '+JSON.stringify(result));
			if(result.data){
				setRptFin(result.data);
			}
			else{
				props.errorMessage("Error retrieving rpt_fin data: "+result.message);
			}
		});
	}

	console.log('RptStoreBalanceSheet.js render departments');
	let departments = rpt_dpt.filter(rpt => rpt.F1034 === 3 || rpt.F1034 === 6).map(record => record.F03).filter(onlyUnique).map((record, index) => (
		<div key={'dept_'+index} className='row'>
			<div className='col-md-1 d-none d-md-block m-0 p-1'>{record}</div>
			<div className='col-6 col-md-5 m-0 p-1'>{deptName(rpt_dpt, record)}</div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'>{rpt_dptTotal(record).toFixed(2)}</div>
			<div className='col-3 col-md-2 m-0 p-1'></div>
			<div className='col-3 col-md-2 m-0 p-1'>{rpt_dptTotal(record).toFixed(2)}</div>
		</div>
	));

	let taxes = rpt_fin.filter(rpt => tlzDetail(props.tlz_tab, rpt.F1034, 'F1179') === 1400 && tlzDetail(props.tlz_tab, rpt.F1034, 'F1253') === 'C').map(record => record.F1034).filter(onlyUnique).map((record, index) => (
		<div key={'taxes_'+index} className='row'>
			<div className='col-md-1 d-none d-md-block m-0 p-1'>{record}</div>
			<div className='col-6 col-md-5 m-0 p-1'>{tlzName(rpt_fin, record)}</div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
			<div className='col-3 col-md-2 m-0 p-1'></div>
			<div className='col-3 col-md-2 m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
		</div>
	));

	let plus = rpt_fin.filter(rpt => tlzDetail(props.tlz_tab, rpt.F1034, 'F1179') === 1500 && tlzDetail(props.tlz_tab, rpt.F1034, 'F1253') === 'C').map(record => record.F1034).filter(onlyUnique).map((record, index) => (
		<div key={'plus_'+index} className='row'>
			<div className='col-md-1 d-none d-md-block m-0 p-1'>{record}</div>
			<div className='col-6 col-md-5 m-0 p-1'>{tlzName(rpt_fin, record)}</div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
			<div className='col-3 col-md-2 m-0 p-1'></div>
			<div className='col-3 col-md-2 m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
		</div>
	));

	console.log('RptStoreBalanceSheet.js render revenues');
	let revenues = rpt_fin.filter(rpt => (rpt.F1034 >= 900 && rpt.F1034 <= 999)).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
		<div key={'rev_'+index} className='row'>
			<div className='col-md-1 d-none d-md-block m-0 p-1'>{record}</div>
			<div className='col-md-5 d-none d-md-block m-0 p-1'>{tlzName(rpt_fin, record)}</div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
		</div>
	));

	console.log('RptStoreBalanceSheet.js render loans');
	let loans = rpt_fin.filter(rpt => (rpt.F1034 >= 1000 && rpt.F1034 <= 1099)).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
		<div key={'loan_'+index} className='row'>
			<div className='col-md-1 d-none d-md-block m-0 p-1'>{record}</div>
			<div className='col-6 col-md-5 m-0 p-1'>{tlzName(rpt_fin, record)}</div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
			<div className='col-3 col-md-2 m-0 p-1'></div>
			<div className='col-3 col-md-2 m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
		</div>
	));

	console.log('RptStoreBalanceSheet.js render pickups');
	let pickups = rpt_fin.filter(rpt => (rpt.F1034 >= 1100 && rpt.F1034 <= 1199)).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
		<div key={'pickup_'+index} className='row'>
			<div className='col-md-1 d-none d-md-block m-0 p-1'>{record}</div>
			<div className='col-6 col-md-5 m-0 p-1'>{tlzName(rpt_fin, record)}</div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
			<div className='col-3 col-md-2 m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
			<div className='col-3 col-md-2 m-0 p-1'></div>
		</div>
	));

	console.log('RptStoreBalanceSheet.js render indrawer');
	let indrawer = rpt_fin.filter(rpt => (rpt.F1034 >= 1300 && rpt.F1034 <= 1399)).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
		<div key={'drw_'+index} className='row'>
			<div className='col-md-1 d-none d-md-block m-0 p-1'>{record}</div>
			<div className='col-6 col-md-5 m-0 p-1'>{tlzName(rpt_fin, record)}</div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
			<div className='col-3 col-md-2 m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
			<div className='col-3 col-md-2 m-0 p-1'></div>
		</div>
	));

	console.log('RptStoreBalanceSheet.js render indrawer');
	let shortover = rpt_fin.filter(rpt => (rpt.F1034 >= 1400 && rpt.F1034 <= 1499)).map(record => record.F1034).filter(onlyUnique).map((record, index) => (
		<div key={'so_'+index} className='row'>
			<div className='col-md-1 d-none d-md-block m-0 p-1'>{record}</div>
			<div className='col-6 col-md-5 m-0 p-1'>{tlzName(rpt_fin, record)}</div>
			<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
			<div className='col-3 col-md-2 m-0 p-1'></div>
			<div className='col-3 col-md-2 m-0 p-1'>{rptTotal(rpt_fin, record).toFixed(2)}</div>
		</div>
	));

	console.log('RptStoreBalanceSheet.js render return');

	const StoreBalanceSheet = () => {
		if(rpt_dpt.filter(rpt => rpt.F1034 === 3 || rpt.F1034 === 6).length > 0 && rpt_fin.length > 0){
				return (
			<div id='ReportData' className='container'>
				<div className='row'>
					<div className='col-md-1 d-none d-md-block m-0 p-1'>&nbsp;</div>
					<div className='col-6 col-md-5 m-0 p-1'><b>Description</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'><b>Amount</b></div>
					<div className='col-3 col-md-2 m-0 p-1'><b>Debit</b></div>
					<div className='col-3 col-md-2 m-0 p-1'><b>Credit</b></div>
				</div>
				<hr className='m-1 p-0' />
				{departments}
				<div className='row'>
					<div className='col-md-1 d-none d-md-block m-0 p-1'>&nbsp;</div>
					<div className='col-md-5 d-none d-md-block m-0 p-1'><b>Total Sales</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'><b>{totalSales().toFixed(2)}</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
				</div>
				<hr className='m-1 p-0' />
				{taxes}
				<div className='row'>
					<div className='col-md-1 d-none d-md-block m-0 p-1'>&nbsp;</div>
					<div className='col-md-5 d-none d-md-block m-0 p-1'><b>Total Taxes</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'><b>{totalTaxes().toFixed(2)}</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
				</div>
				<hr className='m-1 p-0' />
				{plus}
				<div className='row'>
					<div className='col-md-1 d-none d-md-block m-0 p-1'>&nbsp;</div>
					<div className='col-md-5 d-none d-md-block m-0 p-1'><b>Total Plus</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'><b>{totalPlus().toFixed(2)}</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
				</div>
				<hr className='m-1 p-0 d-none d-md-block' />
				{revenues}
				<div className='row'>
					<div className='col-md-1 d-none d-md-block m-0 p-1'>&nbsp;</div>
					<div className='col-md-5 d-none d-md-block m-0 p-1'><b>Total Revenue</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'><b>{totalRevenue().toFixed(2)}</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
				</div>
				<hr className='m-1 p-0' />
				{loans}
				<div className='row'>
					<div className='col-md-1 d-none d-md-block m-0 p-1'>&nbsp;</div>
					<div className='col-md-5 d-none d-md-block m-0 p-1'><b>Total Loan</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'><b>{totalLoans().toFixed(2)}</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
				</div>
				<hr className='m-1 p-0' />
				{pickups}
				<div className='row'>
					<div className='col-md-1 d-none d-md-block m-0 p-1'>&nbsp;</div>
					<div className='col-md-5 d-none d-md-block m-0 p-1'><b>Total Pickups</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'><b>{totalPickups().toFixed(2)}</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
				</div>
				<hr className='m-1 p-0' />
				{indrawer}
				<div className='row'>
					<div className='col-md-1 d-none d-md-block m-0 p-1'>&nbsp;</div>
					<div className='col-md-5 d-none d-md-block m-0 p-1'><b>Total Indrawer</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'><b>{totalInDrawer().toFixed(2)}</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
				</div>
				<hr className='m-1 p-0' />
				{shortover}
				<div className='row m-0 p-1'>
					<div className='col-md-1 d-none d-md-block m-0 p-1'>&nbsp;</div>
					<div className='col-md-5 d-none d-md-block m-0 p-1'><b>Total S/O</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'><b>{totalShortOver().toFixed(2)}</b></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
					<div className='col-md-2 d-none d-md-block m-0 p-1'></div>
				</div>
				<hr className='m-1 p-0' />
				<div className='row'>
					<div className='col-md-3 d-none d-md-block m-0 p-1'>&nbsp;</div>
					<div className='col-6 col-md-5 align-text-right m-0 p-1'><b>Total Debit/Credit</b></div>
					<div className='col-3 col-md-2 m-0 p-1'><b>{totalDebits().toFixed(2)}</b></div>
					<div className='col-3 col-md-2 m-0 p-1'><b>{totalCredits().toFixed(2)}</b></div>
				</div>
			</div>
			);
		}
		else{
			return(
				<RptNoData />
			);
		}
	}

	return (
		<ReportTab id='StoreBalanceSheet'>
			<ReportTab.Header parentId='StoreBalanceSheet' title='Store Balance Sheet'>
				<BtnRefresh parentId='StoreBalanceSheet' action={refresh}/>
				<BtnExpand parentId='StoreBalanceSheet' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='StoreBalanceSheet'>
				{StoreBalanceSheet()} 
			</ReportTab.Expand>
		</ReportTab>
	)
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptStoreBalanceSheet);

