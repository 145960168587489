import React, { useState } from 'react';
import {
	Routes,
	Route
  } from "react-router-dom";
import { useLocalStorage } from './components/hooks/useLocalStorage';
import { useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'
import Theme from './components/themes/Theme';
import ThemeSelector from './components/themes/ThemeSelector';
import ErrorMessage from './components/ErrorMessage';
import Menu from './components/Menu';
import LoginForm from './components/LoginForm';
import ChangePassword from './components/ChangePassword';
import ReportList from './components/ReportList';
import { loginUser, logoutUser } from './components/functions';

import './style.css';

const mapStateToProps = (state) => {
	return {
		version: state.version,
		userLoggedIn: state.userLoggedIn
	}
}

const mapDispatchToProps = { loginUser, logoutUser }

function App(props){
	let location = useLocation();
	if(location.state){
		if(location.state.username && location.state.uid && location.state.session && location.state.isAdmin){
			props.loginUser(location.state.username, location.state.uid, location.state.session, location.state.isAdmin);
		}
	}

	const [theme, setTheme] = useLocalStorage("theme","blue");
	const [showThemeSelector, setShowThemeSelector] = useState(false);

    return (
		<div>
			<Theme theme={theme} />
            <div>
                <div 
                    id='topbar' 
                    className='position-fixed top-0 start-0 container-fluid d-flex px-1 py-2 theme-primary border-bottom border-dark' 
                    style={{ height: '55px' }}
                >
                    <div className='d-flex flex-fill justify-content-start align-self-center'>
                        <h5 className='m-0'>Pocket Reports</h5>
                    </div>
                    <div className='d-flex flex-fill justify-content-end'>
                        {props.userLoggedIn === true ? <Menu showThemeSelector={setShowThemeSelector} /> : null }
                    </div>
                </div>
                <div id='topspacer' style={{ height: '55px' }}></div>
                <div className='container mt-0 ml-auto mr-auto mb-0 pt-0 pb-0 px-0'>
                    <ErrorMessage />
                    {props.userLoggedIn !== true ? <LoginForm /> : null }
                    {
                        props.userLoggedIn === true ? 
                            <Routes>
                                <Route path="/ChangePassword" element={<ChangePassword />} />
                                <Route path="/" element={<ReportList />} />
                            </Routes> 
                        : null 
                    }
                </div>
            </div>
            <div className='d-flex flex-fill justify-content-center my-3'>Version: {props.version}</div>
            <div className='h-100px'></div>
            <ThemeSelector show={showThemeSelector} setShow={setShowThemeSelector} setTheme={setTheme} />
		</div>
    );
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(App);
