import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptSingleTotal from './views/RptSingleTotal';

import { errorMessage, onlyUnique, deptName, rptTotal, rptTotalQty } from '../functions';

const mapStateToProps = (state) => {
    return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
        periodType: state.periodType,
        periodDate: state.periodDate
    }
}

const mapDispatchToProps = { errorMessage }

function RptSubDeptSingleTotal(props) {
	const [rpt_dpt, setRptDpt] = useState([]);

    const refresh = () => {
        console.log('RptDepartmentSingleTotal.js refresh invoked');

        var F1031 = props.periodType;
        var F254 = props.periodDate;

        console.log('RptDepartmentSingleTotal.js requesting: ' + props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_dpt&agent='+props.agent+'&F1031'+F1031+'&F254='+F254+'&F1034=3');

        fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_dpt&agent='+props.agent+'&F1031='+F1031+'&F254='+F254+'&F1034=3')
        .then(response => response.json())
        .then(result => {
            console.log('RptDepartmentSingleTotal.js rpt_dpt result returned');
            console.log('RptDepartmentSingleTotal.js rpt_dpt result: '+JSON.stringify(result));
            if(result.data){
                setRptDpt(result.data);
            }
            else{
                props.errorMessage("Error retrieving rpt_dpt data: "+result.message);
            }
        });
    }

	let rptData = rpt_dpt.map((record) => record.F03).filter(onlyUnique).sort().map((deptNo) => {
		return ({
			code: deptNo,
			description: deptName(rpt_dpt, deptNo),
			qty: rptTotalQty(rpt_dpt.filter((rpt) => rpt.F03 === deptNo), 3),
			dollars: rptTotal(rpt_dpt.filter((rpt) => rpt.F03 === deptNo), 3)
		});
	});

	return (
		<ReportTab id='DepartmentSingleTotal'>
			<ReportTab.Header parentId='DepartmentSingleTotal' title='Department Single Total'>
				<BtnRefresh parentId='DepartmentSingleTotal' action={refresh} />
				<BtnExpand parentId='DepartmentSingleTotal' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='DepartmentSingleTotal'>
				<RptSingleTotal title='Department Net Sales' description='Department' data={rptData} />
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptSubDeptSingleTotal);

