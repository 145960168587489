import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptMultiTotal from './views/RptMultiTotal';

import { errorMessage, cmp, onlyUnique, clkName, tlzName, rptTotalQty, rptTotal } from '../functions';

const mapStateToProps = (state) => {
        return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
                periodType: state.periodType,
                periodDate: state.periodDate
        }
}

const mapDispatchToProps = { errorMessage }

function RptCashierMultiTotal(props){
	const [rpt_clk, setRptClk] = useState([]);

        const refresh = () => {
                console.log('RptCashierMultiTotal.js refresh invoked');

                var F1031 = props.periodType;
                var F254 = props.periodDate;

                console.log('RptCashierMultiTotal.js requesting: '+props.apiServer+'?uid='+props.uid+'&session='+props.session+'&action=rpt_clk_'+F1031+'&agent='+props.agent+'&F254='+F254);
                fetch(props.apiServer+'?uid='+props.uid+'&session='+props.session+'&action=rpt_clk_'+F1031+'&agent='+props.agent+'&F254='+F254)
		.then(response => response.json())
		.then(result => {
                        console.log('RptCashierMultiTotal.js rpt_clk result returned');
                        console.log('RptCashierMultiTotal.js rpt_clk result: '+JSON.stringify(result));
			if(result.data){
	                        setRptClk(result.data);
			}
			else{
				props.errorMessage("Error retrieving rpt_clk data: "+result.message);
			}
                });
        };

	let rptData = rpt_clk.map((record) => record.F1126).filter(onlyUnique).sort().map((cashier) => {
		return ({
			description: cashier + ' ' + clkName(rpt_clk, cashier),
			data: rpt_clk.filter((data) => data.F1126 === cashier).sort(function (a,b) {
				return cmp(a.F1179,b.F1179) || cmp(a.F1034,b.F1034);
			}).map((record) => record.F1034).filter(onlyUnique).map((totalizer) => {
				return ({
					code: totalizer,
					description: tlzName(rpt_clk, totalizer),
					qty: rptTotalQty(rpt_clk.filter(rpt => rpt.F1126 === cashier), totalizer),
					dollars: rptTotal(rpt_clk.filter(rpt => rpt.F1126 === cashier), totalizer)
				});
			})
		});
	});
	console.log('RptCashierMultiTotal.js rptData = '+JSON.stringify(rptData));

	return (
		<ReportTab id='CashierMultiTotal'>
			<ReportTab.Header parentId='CashierMultiTotal' title='Cashier Multi Totals'>
				<BtnRefresh parentId='CashierMultiTotal' action={refresh} />
				<BtnExpand parentId='CashierMultiTotal' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='CashierMultiTotal'>
				<RptMultiTotal data={rptData} />
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptCashierMultiTotal);
