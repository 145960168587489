import React from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from "react-bootstrap/Modal";
import { clearError } from './functions';

const mapStateToProps = (state) => {
    return {
        version: state.version,
        errorMessage: state.errorMessage,
        errorMessageModal: state.errorMessageModal
    }
}

const mapDispatchToProps = { clearError }


function ErrorMessage(props){
	if(props.errorMessage.length > 0){
		console.log('ErrorMessages.js props.errorMessage: '+JSON.stringify(props.errorMessage));
		let errorMessages = null;
		if(Array.isArray(props.errorMessage)){
			errorMessages = props.errorMessage.map((errorMessage,index) => <span className='d-block' key={index}>{errorMessage}</span>);
		}
		else{
			errorMessages = props.errorMessage;
		}



		if(props.errorMessageModal === true){
			return (
				<>
			      <Modal show={true} onHide={props.clearError} centered>
			        <Modal.Header className="m-0 px-2 py-0 alert alert-danger" closeButton>
			          <Modal.Title>ERROR</Modal.Title>
			        </Modal.Header>
			        <Modal.Body className="m-0 p-2">{errorMessages}</Modal.Body>
			      </Modal>
				</>
			);
		}
		else{
		    return (
			<div className="alert alert-danger alert-dismissible fade show mt-2 mb-0 ml-auto mr-auto p-auto" role="alert">
			  <strong>ERROR:</strong> {errorMessages}
			  <button type="button" onClick={props.clearError} className="close" aria-label="Close"><i className='far fa-times-circle' /></button>
			</div>
		    );
		}
	}
	else{
		return null;
	}
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(ErrorMessage);

