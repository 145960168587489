import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptMultiTotal from './views/RptMultiTotal';

import { errorMessage, onlyUnique, cmp, tlzName, deptName, rptTotalQty, rptTotal } from '../functions';

const mapStateToProps = (state) => {
        return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
        periodType: state.periodType,
        periodDate: state.periodDate,
        dept_tab: state.dept_tab,
        tlz_tab: state.tlz_tab
        }
}

const mapDispatchToProps = { errorMessage }

function RptDepartmentMultiTotal(props){
	const [rpt_dpt, setRptDpt] = useState([]);

        const refresh = () => {
            console.log('RptDepartmentMultiTotal.js refresh invoked');

            var F1031 = props.periodType;
            var F254 = props.periodDate;

            console.log('RptDepartmentMultiTotal.js requesting: ' + props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_dpt&agent='+props.agent+'&F1031='+F1031+'&F254='+F254);

            fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_dpt&agent='+props.agent+'&F1031='+F1031+'&F254='+F254)
            .then(response => response.json())
            .then(result => {
                console.log('RptDepartmentMultiTotal.js rpt_dpt result returned');
                console.log('RptDepartmentMultiTotal.js rpt_dpt result: '+JSON.stringify(result));
                if(result.data){
                    setRptDpt(result.data);
                }
                else{
                    props.errorMessage("Error retrieving rpt_dpt data: "+result.message);
                }
            });
        };

	// FORMAT REPORT DATA FOR MULTI TOTAL REPORT
	var rptData = rpt_dpt.map((record) => record.F03).filter(onlyUnique).sort().map((deptNo) => {
		return ({
			description: deptNo + ' ' + deptName(rpt_dpt, deptNo),
			data: rpt_dpt.filter((data) => data.F03 === deptNo).sort(function (a, b) {
				return cmp(a.F1179, b.F1179) || cmp(a.F1034, b.F1034);
			}).map((record) => record.F1034).filter(onlyUnique).map((totalizer) => {
				return ({
					code: totalizer,
					description: tlzName(rpt_dpt, totalizer),
					qty: rptTotalQty(rpt_dpt.filter((rpt) => rpt.F03 === deptNo), totalizer),
					dollars: rptTotal(rpt_dpt.filter((rpt) => rpt.F03 === deptNo), totalizer)
				})
			})
		});
	});
	console.log('RptDepartmentMultiTotal.js rptData = '+JSON.stringify(rptData));

	return (
		<ReportTab id='DepartmentMultiTotals'>
			<ReportTab.Header parentId='DepartmentMultiTotals' title='Dept. Multi Totals'>
				<BtnRefresh parentId='DepartmentMultiTotals' action={refresh} />
				<BtnExpand parentId='DepartmentMultiTotals' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='DepartmentMultiTotals'>
				<RptMultiTotal data={rptData} />
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptDepartmentMultiTotal);
