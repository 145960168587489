import React from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from './ReportTab';
import BtnExpand from './buttons/BtnExpand';
import { setPeriod, formatDate, setPeriodDate, formatDateFromInput } from './functions';

const mapStateToProps = (state) => {
        return {
			period: state.period,
			periodDate: state.periodDate
        }
}

const mapDispatchToProps = { setPeriod, setPeriodDate }

function ReportWizard(props){
	var btnToday = 'btn-primary';
	var btnYesterday = 'btn-primary';
	var btnWeek = 'btn-primary';
	var btnMonth = 'btn-primary';
	var btnYear = 'btn-primary';

	var btnLastYearToday = 'btn-primary';
	var btnLastYearYesterday = 'btn-primary';
	var btnLastYearWeek = 'btn-primary';
	var btnLastYearMonth = 'btn-primary';
	var btnLastYearYear = 'btn-primary';

	var btnLastWeek = 'btn-primary';
	var btnLastMonth = 'btn-primary';

	var btnCustom = 'btn-primary';

	if (props.period === 'today')  btnToday = 'btn-success';
	if (props.period === 'yesterday')  btnYesterday = 'btn-success';
	if (props.period === 'week')  btnWeek = 'btn-success';
	if (props.period === 'month')  btnMonth = 'btn-success';
	if (props.period === 'year')  btnYear = 'btn-success';

	if (props.period === 'lastyear_today')  btnLastYearToday = 'btn-success';
	if (props.period === 'lastyear_yesterday')  btnLastYearYesterday = 'btn-success';
	if (props.period === 'lastyear_week')  btnLastYearWeek = 'btn-success';
	if (props.period === 'lastyear_month')  btnLastYearMonth = 'btn-success';
	if (props.period === 'lastyear_year')  btnLastYearYear = 'btn-success';

	if (props.period === 'lastweek')  btnLastWeek = 'btn-success';
	if (props.period === 'lastmonth')  btnLastMonth = 'btn-success';

	if (props.period === 'custom')  btnCustom = 'btn-success';

	const dateChange = (event) => {
		console.log('dateChange invoked');
		props.setPeriodDate(formatDateFromInput(event.target.value));
	}

	return (
		<ReportTab id='ReportWizard' style={{position:"absolute",top:'0',left:'0'}}>
			<ReportTab.Header parentId='ReportWizard' title='Select Period'>
				<BtnExpand parentId='ReportWizard' />
			</ReportTab.Header>
			<ReportTab.AlwaysShow>
				<div className='row flex-nowrap m-0 p-0'>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnYesterday } onClick={() => props.setPeriod('yesterday')}>Yesterday</button></div>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnToday } onClick={() => props.setPeriod('today')}>Today</button></div>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnWeek } onClick={() => props.setPeriod('week')}>This Week</button></div>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnMonth } onClick={() => props.setPeriod('month')}>This Month</button></div>
					<div className='d-grid col m-0 py-0 px-1'><button className={'h-50px btn btn-sm p-0 '+btnYear } onClick={() => props.setPeriod('year')}>This Year</button></div>
				</div>
			</ReportTab.AlwaysShow>
			<ReportTab.Expand parentId='ReportWizard'>
				<div className='row flex-nowrap m-0 p-0'>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnLastYearYesterday } onClick={() => props.setPeriod('lastyear_yesterday')}>Last Year Yesterday</button></div>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnLastYearToday } onClick={() => props.setPeriod('lastyear_today')}>LY Today</button></div>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnLastYearWeek } onClick={() => props.setPeriod('lastyear_week')}>LY This Week</button></div>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnLastYearMonth } onClick={() => props.setPeriod('lastyear_month')}>LY This Month</button></div>
					<div className='d-grid col m-0 py-0 px-1'><button className={'h-50px btn btn-sm p-0 '+btnLastYearYear } onClick={() => props.setPeriod('lastyear_year')}>Last Year</button></div>
				</div>
				<div className='row flex-nowrap my-1 mx-0 p-0'>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'>&nbsp;</div>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'>&nbsp;</div>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnLastWeek } onClick={() => props.setPeriod('lastweek')}>Last Week</button></div>
					<div className='d-grid col m-0 py-0 ps-1 pe-0'><button className={'h-50px btn btn-sm p-0 '+btnLastMonth } onClick={() => props.setPeriod('lastmonth')}>Month Week</button></div>
					<div className='d-grid col m-0 py-0 px-1'><button className={'h-50px btn btn-sm p-0 '+btnCustom } onClick={() => props.setPeriod('custom')}>Custom</button></div>
				</div>
				<div className='row m-0 p-0'>
					<div className='col form-floating mt-2 mb-0 p-1'>
						<input id='date_selected' name='date_selected' type='date' value={formatDate(props.periodDate)} onChange={dateChange} className='form-control' />
						<label htmlFor='date_selected'>Date Selected</label>
					</div>
				</div>
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(ReportWizard);

