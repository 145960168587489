export const errorMessage = (errorMessage, errorMessageModal = true) => {
	return {
		type: 'errorMessage',
		errorMessage: errorMessage,
		errorMessageModal: errorMessageModal
	}
}

export const clearError = () => {
	return {
		type: 'errorMessage',
		errorMessage: ''
	}
}

export const loginUser = (username, uid, session, isAdmin = 'N') => {
	console.log('functions.js loginUser invoked');
	console.log('functions.js loginUser username: '+username);

	return {
		type: 'loginUser',
		username: username,
		uid: uid,
		session: session,
		isAdmin: isAdmin
	}
}

export const logoutUser = () => {
	return {
		type: 'logoutUser'
	}
}

export const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}

export const setAgent = (agent, weekstart = 0) => {
	console.log('functions.js setAgent invoked');
	console.log('functions.js setAgent agent: '+agent);

	return {
		type: 'setAgent',
		agent: agent,
		weekstart: weekstart
	};
}

export const setPeriod = (period) => {
	console.log('functions.js setPeriod invoked');
	console.log('functions.js period = '+JSON.stringify(period));

	var periodType = 'D';
	var today = new Date();
	var periodDate = String(today.getMonth() + 1).padStart(2, '0') + '/' + String(today.getDate()).padStart(2, '0') + '/' + today.getFullYear();

	if(period === 'yesterday'){
		periodType = 'D';

		var yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate()-1);
		periodDate = String(yesterday.getMonth() + 1).padStart(2, '0') + '/' + String(yesterday.getDate()).padStart(2, '0') + '/' + yesterday.getFullYear();
	}

	if(period === 'week'){
		//var endOfWeek = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate() + 6 - today.getDay()); 
		var endOfWeek = new Date(today.setDate(today.getDate() + 6 - today.getDay())); 
		periodType = 'W';
		periodDate = String(endOfWeek.getMonth() + 1).padStart(2, '0') + '/' + String(endOfWeek.getDate()).padStart(2, '0') + '/' + endOfWeek.getFullYear();
	}

	if(period === 'month'){
		var endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		periodType = 'M';
		periodDate = String(endOfMonth.getMonth() + 1).padStart(2, '0') + '/' + String(endOfMonth.getDate()).padStart(2, '0') + '/' + endOfMonth.getFullYear();
	}

	if(period === 'year'){
		periodType = 'Y';
		periodDate = '12/31/' + today.getFullYear();
	}

	if(period === 'lastyear_today'){
		periodType = 'D';

		var lastyear_today = new Date();
		periodDate = String(lastyear_today.getMonth() + 1).padStart(2, '0') + '/' + String(lastyear_today.getDate()).padStart(2, '0') + '/' + (lastyear_today.getFullYear() - 1);
	}

	if(period === 'lastyear_yesterday'){
		periodType = 'D';

		var lastyear_yesterday = new Date(today);
		lastyear_yesterday.setDate(lastyear_yesterday.getDate()-1);
		periodDate = String(lastyear_yesterday.getMonth() + 1).padStart(2, '0') + '/' + String(lastyear_yesterday.getDate()).padStart(2, '0') + '/' + (lastyear_yesterday.getFullYear() - 1);
	}

	if(period === 'lastyear_week'){
		var lastyear_endOfWeek = new Date(today.setDate(today.getDate() + 6 - today.getDay()));
		periodType = 'W';
		periodDate = String(lastyear_endOfWeek.getMonth() + 1).padStart(2, '0') + '/' + String(lastyear_endOfWeek.getDate()).padStart(2, '0') + '/' + (lastyear_endOfWeek.getFullYear() - 1);
	}

	if(period === 'lastyear_month'){
		var lastyear_endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		periodType = 'M';
		periodDate = String(lastyear_endOfMonth.getMonth() + 1).padStart(2, '0') + '/' + String(lastyear_endOfMonth.getDate()).padStart(2, '0') + '/' + (lastyear_endOfMonth.getFullYear() - 1);
	}

	if(period === 'lastyear_year'){
		periodType = 'Y';
		periodDate = '12/31/' + (today.getFullYear() - 1);
	}

	if(period === 'lastweek'){
		var lastweek_endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() - 1));
		periodType = 'W';
		periodDate = String(lastweek_endOfWeek.getMonth() + 1).padStart(2, '0') + '/' + String(lastweek_endOfWeek.getDate()).padStart(2, '0') + '/' + (lastweek_endOfWeek.getFullYear());
	}

	if(period === 'lastmonth'){
		var lastmonth_endOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);
		periodType = 'M';
		periodDate = String(lastmonth_endOfMonth.getMonth() + 1).padStart(2, '0') + '/' + String(lastmonth_endOfMonth.getDate()).padStart(2, '0') + '/' + (lastmonth_endOfMonth.getFullYear());
	}	

	if(period === 'custom'){
		console.log('functions.js setPeriod custom periodDate = '+periodDate);
		periodType = 'D';
	}

	return {
		type: 'setPeriod',
		period: period,
		periodType: periodType,
		periodDate: periodDate
	}
}

export const setPeriodDate = (date) => {
	console.log('functions.js setPeriodDate invoked');
	console.log('functions.js date = '+date);

	return {
		type: 'setPeriod',
		period: 'custom',
		periodType: 'D',
		periodDate: date
	}
}

export const setDepartments = (dept_tab) => {
        return {
                type: 'setDepartments',
                dept_tab: dept_tab
        }
}

export const setSubDepartments = (sdp_tab) => {
        return {
                type: 'setSubDepartments',
                sdp_tab: sdp_tab
        }
}

export const setTotalizers = (tlz_tab) => {
        return {
                type: 'setTotalizers',
                tlz_tab: tlz_tab
        }
}

export const setOperators = (clk_tab) => {
        return {
                type: 'setOperators',
                clk_tab: clk_tab
        }
}

export const setCategories = (cat_tab) => {
        return {
                type: 'setCategories',
                cat_tab: cat_tab
        }
}

export const setFamilyCodes = (fam_tab) => {
        return {
                type: 'setFamilyCodes',
                fam_tab: fam_tab
        }
}

export const setReportCodes = (rpc_tab) => {
        return {
                type: 'setReportCodes',
                rpc_tab: rpc_tab
        }
}

export const deptName = (dept_tab, F03) => {
	console.log('functions.js deptName invoked');
	console.log('functions.js deptName F03: '+F03);
	if(dept_tab){
		if(dept_tab.filter(dept => dept.F03 === F03).length > 0){
			console.log('functions.js deptName found');
			return dept_tab.filter(dept => dept.F03 === F03)[0].F238;
		}
	}
	return 'Undefined '+F03;
}

export const sdpName = (sdp_tab, F04) => {
	console.log('functions.js sdpName invoked');
	console.log('functions.js sdpName F04: '+F04);
	if(sdp_tab){
		if(sdp_tab.filter(sdp => sdp.F04 === F04).length > 0){
			console.log('functions.js sdpName found');
			return sdp_tab.filter(sdp => sdp.F04 === F04)[0].F1022;
		}
	}
	return 'Undefined '+F04;
}

export const tlzName = (tlz_tab, F1034) => {
	console.log('functions.js tlzName invoked');
	console.log('functions.js tlzName tlz_tab: '+JSON.stringify(tlz_tab));
	console.log('functions.js tlzName F1034: '+F1034);
	if(tlz_tab){
		if(tlz_tab.filter(tlz => tlz.F1034 === F1034).length > 0){
			console.log('functions.js tlzName found');
			return tlz_tab.filter(tlz => tlz.F1034 === F1034)[0].F1039;
		}
	}
	return 'Undefined '+F1034;
}

export const tlzDetail = (tlz_tab, F1034, Field) => {
	console.log('functions.js tlzDetail invoked');
	console.log('functions.js tlzDetail F1034: '+F1034);
	if(tlz_tab){
		if(tlz_tab.filter(tlz => tlz.F1034 === F1034).length > 0){
			console.log('functions.js tlzDetail found');
			if(tlz_tab.filter(tlz => tlz.F1034 === F1034)[0][Field]){
				return tlz_tab.filter(tlz => tlz.F1034 === F1034)[0][Field];
			}
		}
	}
	return 'Undefined';
}

export const tlzSectionNum = (tlz_tab, section) => {
	console.log('functions.js tlzSectionNum invoked');
	console.log('functions.js tlzSectionNum section: '+section);
	if(tlz_tab && tlz_tab.filter(tlz => tlz.F1129 === section).length > 0){
		console.log('functions.js tlzSectionNum found');
		if(tlz_tab.filter(tlz => tlz.F1129 === section)[0].F1179){
			return tlz_tab.filter(tlz => tlz.F1129 === section)[0].F1179;
		}
	}
	return 'Undefined';
}

export const tlzSectionName = (tlz_tab, section) => {
	console.log('functions.js tlzSectionName invoked');
	console.log('functions.js tlzSectionName section: '+section);
	if(tlz_tab && tlz_tab.filter(tlz => tlz.F1179 === section).length > 0){
		console.log('functions.js tlzSectionName found');
		if(tlz_tab.filter(tlz => tlz.F1179 === section)[0].F1179){
			return tlz_tab.filter(tlz => tlz.F1179 === section)[0].F1129;
		}
	}
	return 'Undefined';
}

export const clkName = (clk_tab, F1126) => {
	console.log('functions.js clkName invoked');
	console.log('functions.js clkName F1126: '+F1126);
	if(clk_tab.filter(clk => clk.F1126 === F1126).length > 0){
		console.log('functions.js clkName found');
		return clk_tab.filter(clk => clk.F1126 === F1126)[0].F1127;
	}
	return 'Undefined '+F1126;
}

export const clkDetail = (clk_tab, F1126, Field) => {
	console.log('functions.js clkDetail invoked');
	console.log('functions.js clkDetail F1126: '+F1126);
	if(clk_tab && clk_tab.filter(clk => clk.F1126 === F1126).length > 0){
		console.log('functions.js clkDetail found');
		if(clk_tab.filter(clk => clk.F1126 === F1126)[0][Field]){
			return clk_tab.filter(clk => clk.F1126 === F1126)[0][Field];
		}
	}
	return 'Undefined';
}

export const catName = (cat_tab, F17) => {
	console.log('functions.js catName invoked');
	console.log('functions.js catName F17: '+F17);
	if(cat_tab.filter(cat => cat.F17 === F17).length > 0){
		console.log('functions.js catName found');
		return cat_tab.filter(cat => cat.F17 === F17)[0].F1023;
	}
	return 'Undefined '+F17;
}

export const famName = (fam_tab, F16) => {
	console.log('functions.js famName invoked');
	console.log('functions.js famName F16: '+F16);
	if(fam_tab.filter(fam => fam.F16 === F16).length > 0){
		console.log('functions.js famName found');
		return fam_tab.filter(fam => fam.F16 === F16)[0].F1040;
	}
	return 'Undefined '+F16;
}

export const rpcName = (rpc_tab, F18) => {
	console.log('functions.js rpcName invoked');
	console.log('functions.js rpcName F18: '+F18);
	if(rpc_tab.filter(rpc => rpc.F18 === F18).length > 0){
		console.log('functions.js rpcName found');
		return rpc_tab.filter(rpc => rpc.F18 === F18)[0].F1024;
	}
	return 'Undefined '+F18;
}

export const rptTotal = (data, totalizer) => {
       	if(data.filter(rpt => (rpt.F1034 === totalizer)).length > 0){
             	return data.filter(rpt => (rpt.F1034 === totalizer)).reduce((a, b) => {
                	return a + b.F65
        	}, 0);
        }
	return 0;
};

export const rptTotalQty = (data, totalizer) => {
      	if(data.filter(rpt => (rpt.F1034 === totalizer)).length > 0){
        	return data.filter(rpt => (rpt.F1034 === totalizer)).reduce((a, b) => {
                	return a + b.F64
        	}, 0);
        }
	return 0;
};

export const rptTotalTlzRange = (rpt_data, minTlz, maxTlz) => {
	if(rpt_data.length > 0){
		if(rpt_data.filter(rpt => (rpt.F1034 >= minTlz && rpt.F1034 < maxTlz)).length > 0){
			return rpt_data.filter(rpt => (rpt.F1034 >= minTlz && rpt.F1034 < maxTlz)).reduce((a, b) => {
					return a + b.F65
			}, 0);
		}
	}
	return 0;
}

export const showView = (view) => {
	return {
		type: 'showView',
		view: view
	}
}

export const formatDate = (date) => {
	var d = new Date(date),
        	month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

	if (month.length < 2)
        	month = '0' + month;
	if (day.length < 2)
		day = '0' + day;

	return [year, month, day].join('-');
}

export const formatDateFromInput = (date) => {
	console.log('functions.js formatDateFromInput invoked');
	console.log('functions.js formatDateFromInput date = '+date);
	var d = new Date(date.replace(/-/g, '/')),
        	month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

	if (month.length < 2)
        	month = '0' + month;
	if (day.length < 2)
		day = '0' + day;

	var returnDate = [month, day, year].join('/');
	console.log('functions.js formatDateFromInput returnDate = '+returnDate);
	return returnDate;
}

export const cmp = (a,b) => {
        if(a > b) return +1;
        if(a < b) return -1;
	return 0;
}

