import { rptTotal, rptTotalQty } from '../../functions';

function QuickViewStats (props) {
    if(!props.data){
        return null;
    }

    let transactions = rptTotalQty(props.data, 3102);
    let items_sold = rptTotalQty(props.data, 3103).toFixed(0);
    let gross = rptTotal(props.data,2).toFixed(2);
    let net = rptTotal(props.data,3).toFixed(2);
    let avg_basket = (net/transactions).toFixed(2);
    let avg_basket_qty = (items_sold/transactions).toFixed(0);

    return (
        <fieldset className='m-3 border border-1 rounded-3'>
            <legend className='text-center fs-5 fw-bold'>Today</legend>
            <div className='d-flex flex-fill flex-row justify-content-center pt-0 pb-3 px-0'>
                <div className='flex-column pe-3'>
                    <div className='ps-0 pe-0 text-start'>Transactions: {transactions}</div>
                    <div className='ps-0 pe-0 text-start'>Avg Basket: ${avg_basket}</div>
                    <div className='ps-0 pe-0 text-start'>Avg Basket Qty: {avg_basket_qty}</div>
                </div>

                <div className='flex-column ps-3'>
                    <div className='ps-0 pe-0 text-start'>Items Sold: {items_sold}</div>
                    <div className='ps-0 pe-0 text-start'>Gross: ${gross}</div>
                    <div className='ps-0 pe-0 text-start'>Net: ${net}</div>
                </div>
            </div>
        </fieldset>
    );
}

export default QuickViewStats;
