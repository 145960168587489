import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GenerateMenu from './GenerateMenu';

import { logoutUser, setAgent } from './functions';

const mapStateToProps = (state) => {
	return {
		apiServer: state.apiServer,
		uid: state.uid,
		session: state.session,
		isAdmin: state.isAdmin
	}
}

const mapDispatchToProps = { logoutUser, setAgent }

function Menu(props){
	const [stores, setStores] = useState([]);

	useEffect(() => {
			console.log('Menu.js loading list_agents');
			console.log('Menu.js ' + props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=list_agents');

			fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=list_agents')
			.then(response => response.json())
			.then(result => {
                console.log('Menu.js list_agents result returned');
                console.log('Menu.js list_agents result: '+JSON.stringify(result));
                setStores(result.data);
			});
	}, [props.apiServer, props.session, props.uid]);

	let navigate = useNavigate();
	let location = useLocation();

	const logout = () => {
		props.logoutUser();
		navigate("/", {state: {...location.state, ...{
			username: '',
			uid: '',
			session: '',
			isAdmin: 'N'
		}}})
	}

	const changeStore = () => {
		console.log('Menu.js changeStore() invoked');
		props.setAgent('');
		navigate("/", {state: {...location.state}});
	}

	let menu = [
		{
			label: 'Change Store/Location',
			action: changeStore
		},
		{
			label: 'Change Password',
			action: () => navigate('/ChangePassword', {state: {...location.state}})
		},
		{
			label: 'Change Theme',
			action: () => props.showThemeSelector(true)
		},		
		{
			label: 'Logout',
			action: logout
		}
	];

	if(stores.length === 1){
		menu.shift();
	}

	return (
		<GenerateMenu menu={menu} />
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(Menu);
