import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptMultiTotal from './views/RptMultiTotal';

import { errorMessage, onlyUnique, cmp, deptName, sdpName, rptTotalQty, rptTotal } from '../functions';

const mapStateToProps = (state) => {
        return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
                periodType: state.periodType,
                periodDate: state.periodDate,
                dept_tab: state.dept_tab,
                tlz_tab: state.tlz_tab
        }
}

const mapDispatchToProps = { errorMessage }

function RptSubDeptByDept(props){
	const [rpt_sub, setRptSub] = useState([]);

	const refresh = () => {
		console.log('RptSubDeptByDept.js refresh invoked');

		var F1031 = props.periodType;
		var F254 = props.periodDate;

		console.log('RptSubDeptByDept.js requesting: '+props.apiServer+'?uid='+props.uid+'&session='+props.session+'&action=rpt_sub&agent='+props.agent+'&F1031='+F1031+'&F1034=3&F254='+F254);
		fetch(props.apiServer+'?uid='+props.uid+'&session='+props.session+'&action=rpt_sub&agent='+props.agent+'&F1031='+F1031+'&F1034=3&F254='+F254)
		.then(response => response.json())
		.then(result => {
			console.log('RptSubDeptByDept.js rpt_sub result returned');
			console.log('RptSubDeptByDept.js rpt_sub result: '+JSON.stringify(result));
			if(result.data){
				setRptSub(result.data);
			}
			else{
				props.errorMessage("Error retrieving rpt_sub data: "+result.message);
			}
		});
	}

	console.log()

	let rptData = rpt_sub.map(record => record.F03).filter(onlyUnique).map(dept => {
		return ({
			description: deptName(rpt_sub,dept),
			total: rptTotal(rpt_sub.filter((record) => record.F03 === dept), 3),
			data: rpt_sub.filter((data) => data.F03 === dept).sort(function (a, b) {
				return cmp(a.F04,b.F04)
			}).map((record) => record.F04).filter(onlyUnique).map((sdp) => {
				return ({
					code: sdp,
					description: sdpName(rpt_sub, sdp),
					qty: rptTotalQty(rpt_sub.filter((record) => record.F04 === sdp), 3),
					dollars: rptTotal(rpt_sub.filter((record) => record.F04 === sdp), 3)
				});
			})
		});
	});

	return (
		<ReportTab id='SubDeptByDept'>
			<ReportTab.Header parentId='SubDeptByDept' title='Sub-dept. By Dept.'>
				<BtnRefresh parentId='SubDeptByDept' action={refresh} />
				<BtnExpand parentId='SubDeptByDept' action={refresh} />
			</ReportTab.Header>
			<ReportTab.Expand parentId='SubDeptByDept'>
				<RptMultiTotal data={rptData} />
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptSubDeptByDept);

