import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from '../ReportTab';
import BtnRefresh from '../buttons/BtnRefresh';
import BtnExpand from '../buttons/BtnExpand';
import RptSingleTotal from './views/RptSingleTotal';

import { errorMessage, rpcName } from '../functions';

const mapStateToProps = (state) => {
        return {
		apiServer: state.apiServer,
		agent: state.agent,
		uid: state.uid,
		session: state.session,
                periodType: state.periodType,
                periodDate: state.periodDate
        }
}

const mapDispatchToProps = { errorMessage }

function RptOthersReportCodeSingleTotal(props) {
	const [rpt_rpc, setRptRpc] = useState([]);

        const refresh = () => {
                console.log('RptOthersReportCodeSingleTotal.js refresh invoked');

                var F1031 = props.periodType;
                var F254 = props.periodDate;

                console.log('RptOthersReportCodeSingleTotal.js requesting: ' + props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_rpc&agent='+props.agent+'&F1031'+F1031+'&F254='+F254+'&F1034=3');
                fetch(props.apiServer + '?uid='+props.uid+'&session='+props.session+'&action=rpt_rpc&agent='+props.agent+'&F1031='+F1031+'&F254='+F254+'&F1034=3')
		.then(response => response.json())
		.then(result => {
                        console.log('RptOthersReportCodeSingleTotal.js rpt_rpc result returned');
                        console.log('RptOthersReportCodeSingleTotal.js rpt_rpc result: '+JSON.stringify(result));
			if(result.data){
	                        setRptRpc(result.data);
			}
			else{
				props.errorMessage("Error retrieving rpt_rpc data: "+result.message);
			}
                });
        }

        let rptData = rpt_rpc.map((record,index) => {
                return {
                        code: record.F18,
                        description: rpcName(rpt_rpc, record.F18),
                        qty: record.F64,
                        dollars: record.F65
                };
        });

	return (
		<ReportTab id='ReportCodeSingleTotal'>
			<ReportTab.Header parentId='ReportCodeSingleTotal' title='Report Code Single Total'>
				<BtnRefresh parentId='ReportCodeSingleTotal' action={refresh} />
				<BtnExpand parentId='ReportCodeSingleTotal' action={refresh}/>
			</ReportTab.Header>
			<ReportTab.Expand parentId='ReportCodeSingleTotal'>
				<RptSingleTotal title='Report Code Net Sales' description='Report Code' data={rptData} />
			</ReportTab.Expand>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(RptOthersReportCodeSingleTotal);

