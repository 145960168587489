import { Collapse } from 'bootstrap';

function BtnExpand(props){
    const refresh = (e) => {
        // debugger;
        if (e.target.closest('button').classList.contains('collapsed')){
            return;
        }
		console.log('BtnRefresh.js refresh invoked');
		let refreshIcon = document.getElementById(props.parentId+'Refresh');
		refreshIcon.classList.add("refresh");
			window.setTimeout( function () { refreshIcon.classList.remove("refresh"); }, 500 );

		props.action();
		let collapsible = document.getElementById('collapse_'+props.parentId);
		if(!collapsible.classList.contains("show")){
			console.log('BtnRefresh.js bootstrap expand');
			new Collapse(document.getElementById('collapse_'+props.parentId));
		}
	}
    return (
        <button
            className={props.expanded ? 'm-1 btn btn-sm btn-light' : 'm-1 btn btn-sm btn-light collapsed'} data-bs-toggle="collapse"
            data-bs-target={'#collapse_'+props.parentId}
            aria-expanded={props.expanded ? 'true' : 'false'} 
            aria-controls={'collapse_'+props.parentId}
            onClick={refresh}
        >
            <i className='text-black fas fa-angle-double-down' />
        </button>
    );
}

export default BtnExpand;