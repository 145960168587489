import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportTab from './ReportTab';

import { setAgent } from './functions';

const mapStateToProps = (state) => {
	return {
		version: state.version,
		apiServer: state.apiServer,
		uid: state.uid,
		session: state.session
	}
}

const mapDispatchToProps = { setAgent }

function LocationList(props){
	const [locations, setLocations] = useState([]);

	useEffect(() => {
		console.log('LocationList.js invoked');
		setLocations(props.stores.filter(record => record.store === props.store));
	}, [props, props.stores]);

	useEffect(() => {
		if(locations.length === 1){
			console.log('LocationList.js only 1 location found');
			let details = props.stores.filter(record => record.store === props.store)[0];
			props.setAgent(details.agent, details.weekstart);
		}
	}, [locations, props, props.stores]);

	return(
		<ReportTab>
			<ReportTab.Header title='Locations'></ReportTab.Header>
			<ReportTab.AlwaysShow>
				{
					<div className='m-0 p-1 d-grid gap-2'>
						{
                            locations.map((record, index) => 
                                <button 
                                    key={'location_'+index} 
                                    onClick={() => props.setAgent(record.agent, record.weekstart)} 
                                    className='btn btn-block btn-primary'
                                >
                                    {record.location}
                                </button>)
                        }
					</div>
				} 
			</ReportTab.AlwaysShow>
		</ReportTab>
	);
}

export default connect(
        mapStateToProps,
        mapDispatchToProps
)(LocationList);
