import { createStore } from "redux";

const today = new Date();
const todayDate = String(today.getMonth() + 1).padStart(2, '0') + '/' + String(today.getDate()).padStart(2, '0') + '/' + today.getFullYear();

const packageJson = require('../package.json');

const initialState = {
	version: packageJson.version,
	apiServer: 'https://api.gldstools.com/index.php',
	agent: '',
	weekstart: 0,
	errorMessage: [],
	errorMessageModal: false,
	userLoggedIn: false,
	username: '',
	uid: '',
	session: '',
	isAdmin: 'N',
	view: 'ReportList',
	period: 'today',
	periodType: 'D',
	periodDate: todayDate,
	dept_tab: [],
	sdp_tab: [],
	tlz_tab: [],
    lastWeekColor: "#198722",
    thisWeekColor: "#578AC8"
    
};

const rootReducer = function(state = initialState, action){
	let newState = {...state};
	switch(action.type){
		case 'errorMessage':
			console.log('stateMgr.js errorMessage invoked');
			if(action.errorMessage === ''){
				newState['errorMessage'] = [];
			}
			else{
				if(Array.isArray(state['errorMessage'])){
					newState['errorMessage'] = state['errorMessage'].concat(action.errorMessage);
				}
				else{
					newState['errorMessage'] = new Array(action.errorMessage);
				}
			}
			newState['errorMessageModal'] = action.errorMessageModal;
			return newState;
		case 'loginUser':
			console.log('stateMgr.js loginUser invoked');
			newState['errorMessage'] = '';
			newState['userLoggedIn'] = true;
			newState['username'] = action.username;
			newState['uid'] = action.uid;
			newState['session'] = action.session;
			newState['isAdmin'] = action.isAdmin;
			return newState;
		case 'logoutUser':
			console.log('stateMgr.js logoutUser invoked');
			return initialState;
		case 'setAgent':
			console.log('stateMgr.js setAgent invoked');
			newState['agent'] = action.agent;
			newState['weekstart'] = action.weekstart;
			return newState;
		case 'setPeriod':
			console.log('stateMgr.js setPeriod invoked');
			console.log('stateMgr.js action = '+JSON.stringify(action));
			newState['period'] = action.period;
			newState['periodType'] = action.periodType;
			newState['periodDate'] = action.periodDate;
			return newState;
		case 'setDepartments':
			console.log('stateMgr.js setDepartments invoked');
			newState['dept_tab'] = action.dept_tab;
			return newState;
		case 'setSubDepartments':
			console.log('stateMgr.js setSubDepartments invoked');
			newState['sdp_tab'] = action.sdp_tab;
			return newState;
		case 'setTotalizers':
			console.log('stateMgr.js setTotalizers invoked');
			newState['tlz_tab'] = action.tlz_tab;
			return newState;
		case 'setOperators':
			console.log('stateMgr.js setOperators invoked');
			newState['clk_tab'] = action.clk_tab;
			return newState;
		case 'setCategories':
			console.log('stateMgr.js setCategories invoked');
			newState['cat_tab'] = action.cat_tab;
			return newState;
		case 'setFamilyCodes':
			console.log('stateMgr.js setFamilyCodes invoked');
			newState['fam_tab'] = action.fam_tab;
			return newState;
		case 'setReportCodes':
			console.log('stateMgr.js setReportCodes invoked');
			newState['rpc_tab'] = action.rpc_tab;
			return newState;
		case 'showView':
			console.log('stateMgr.js showView invoked');
			newState['view'] = action.view;
			return newState;
        case 'setChartColor':
            console.log('stateMgr.js setChartColor invoked');
            newState['lastWeekColor'] = action.lastWeekColor;
            newState['thisWeekColor'] = action.thisWeekColor;
            return newState;
		case 'resetState':
			return initialState;
		default:
			console.log('stateMgr.js default invoked');
			console.log('stateMgr.js action: '+JSON.stringify(action));
			return newState;
	}
}

export default createStore(rootReducer);
